import React from "react";
import Chart from "react-apexcharts";

function Users(props) {
  const { surgeon_percentage, anaesthetist_percentage } = props;

  const series = [surgeon_percentage, anaesthetist_percentage];
  const options = {
    chart: {
      fontFamily: "Poppins, sans-serif",
    },
    title: {
      text: "User",
      align: "left",
      style: {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
    labels: ["Surgeon", "Anaesthetist"],
    legend: {
      position: "bottom",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
        },
      },
    },
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <Chart
        options={options}
        series={series}
        type="donut"
        width="340"
        height={250}
        align={"left"}
      />
    </div>
  );
}

export default Users;
