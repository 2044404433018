import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";

function MapChart() {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    axios.get('/distributed_map').then((res) => {
      setSeries([
        {
          data:res.data.data
        }
      ])
    })
  }, []);

  const options = {
    legend: {
      show: false,
    },
    chart: {
      height: 350,
      type: "treemap",
    },
    title: {
      text: "Distibuted Treemap (different color for each cell)",
      align: "left",
      style: {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
    colors: [
      "#3B93A5",
      "#F7B844",
      "#ADD8C7",
      "#EC3C65",
      "#CDD7B6",
      "#C1F666",
      "#D43F97",
      "#1E5D8C",
      "#421243",
      "#7F94B0",
      "#EF6537",
      "#C0ADDB",
    ],
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
      },
    },
  };
  return (
    <div className="map-chart ">
      <ReactApexChart
        options={options}
        series={series}
        type="treemap"
        height={250}
        align={"right"}
      />
    </div>
  );
}

export default MapChart;
