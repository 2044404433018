import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";

function AcceptenceChart() {
  const [date, setDate] = useState([]);
  const [series, setSeries] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    axios
      .get(`/acceptance_rejection?date=${selectedDate}`)
      .then((res) => {
        setDate(res.data?.date === null ? [] : JSON.parse(res.data?.date));
        setSeries(res.data?.data === null ? [] : JSON.parse(res.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedDate]);
  const options = {
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      width: "100%",
    },
    colors: ["#00e396", "#008ffb"],
    title: {
      text: "Surgery Booking",
      align: "left",
      style: {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: date,
    },
    yaxis: {},
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };
  return (
    <div style={{ marginRight: "10px", marginTop: "10px" }}>
      <div className="chart-input-block">
        <select
          className="input-field"
          value={selectedDate}
          onChange={(e) => {
            setSelectedDate(e.target.value);
          }}
        >
          <option value=""> Select</option>
          <option value="1"> Last 7 days</option>
          <option value="2">Last month</option>
          <option value="3">Last year</option>
        </select>
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={250}
        align={"right"}
      />
    </div>
  );
}

export default AcceptenceChart;
