import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";

function TransactionChart(props) {
  const [day, setDay] = useState([]);
  const [amount, setAmount] = useState([{ name: "Total Amount", data: null }]);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    axios
      .get(`/transaction_detail?date=${selectedDate}`)
      .then((res) => {
        setAmount(res.data.map((amount_from_api) => amount_from_api.amount));
        setDay(res.data.map((day_from_api) => day_from_api.day));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedDate]);
  const series = [
    {
      name: "transaction collected",
      data: amount,
    },
  ];
  // const series = [{
  //   name: "Income",
  //   data: [10, 41, 35, 51, 49, 62, 69, 45, 79]
  // }];
  const options = {
    chart: {
      type: "line",
      fontFamily: "Poppins, sans-serif",
      width: "100%",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: `Transaction Collected - ${props?.total_collection}`,
      align: "left",
      style: {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: day,
    },
  };
  return (
    <div style={{ marginRight: "10px" }}>
      <div className="chart-input-block">
        <select
          className="input-field"
          value={selectedDate}
          onChange={(e) => {
            setSelectedDate(e.target.value);
          }}
        >
          <option value=""> Select</option>
          <option value="1"> Last 7 days</option>
          <option value="2">Last month</option>
          <option value="3">Last year</option>
        </select>
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={250}
        align={"right"}
      />
    </div>
  );
}

export default TransactionChart;
